import React from 'react'
import { useTranslation } from 'react-i18next'

import CardProfile from './card-profile'

export default function ProfileList() {
  const { t } = useTranslation()

  return (
    <>
<div className="w-full max-w-6xl mx-auto px-4 sm:px-0 lg:px-0 justify-center justify-center mt-[30px] mb-[100px]">
  <div className='w-full flex justify-center flex-wrap'>
        <CardProfile
          image={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG_oM2JRKjnKfsJTW04ud6AziSfy-FS_2hK-q0o8LOdw&s`}
          name={`Silvia Triebl`}
          title={`Founder & CEO`}
          company={`Benjamiin 4 Kids GmbH`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/silviatriebl/?originalSubdomain=ch'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

        <CardProfile
          image={`https://media.licdn.com/dms/image/C4D03AQE_7XUNAxEL5Q/profile-displayphoto-shrink_400_400/0/1554447833883?e=1728518400&v=beta&t=IEdQZ3whH7nfk0o4c0xFK4kojNHF6lnOZu2i1tNxsZw`}
          name={`Patricia FALCO Beccalli`}
          title={`Investor`}
          company={`Falco Global Partners`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/patricia-falco-beccalli-szarvas-6abbb8/?originalSubdomain=ch'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

      <CardProfile
          image={`https://media.licdn.com/dms/image/C5603AQEpIEOi6QZHtA/profile-displayphoto-shrink_400_400/0/1516359532735?e=1728518400&v=beta&t=uxv8TcEDUu5kXRZpfA55J6Ky0jKIxyMxs_zfMUa51qQ`}
          name={`RENé EICHENBERGER`}
          title={`Investor`}
          company={`New Venture Associates`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/ren%C3%A9-eichenberger-4394318/'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

      <CardProfile
          image={`https://media.licdn.com/dms/image/D4E03AQHxl-1RdCfkjQ/profile-displayphoto-shrink_400_400/0/1691063849461?e=1728518400&v=beta&t=SBdwpRNnttCDBAva-OM_c26Xv-RAszaM0TTMKDKgNLU`}
          name={`Abouzar Rahmani`}
          title={`Investor`}
          company={`FoodYoung Labs & Studio`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/abouzar-rahmani-b3b890139'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

      <CardProfile
          image={`https://media.licdn.com/dms/image/D4E03AQHi1Wm-kAjZPg/profile-displayphoto-shrink_400_400/0/1701692642388?e=1728518400&v=beta&t=J4DQGtv9q2WnCnXB22vPY6vnoZdsDo_nU6xsZM_njZ8`}
          name={`Guillaume Thomassin`}
          title={`Investor`}
          company={`Simple Task Switzerland`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/guillaume-thomassin/'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

      <CardProfile
          image={`https://media.licdn.com/dms/image/D4E03AQECYZSg4NNKkA/profile-displayphoto-shrink_400_400/0/1712491403322?e=1728518400&v=beta&t=sUUMkIBSuko0T9FyPtTxOYUnbNFK06z4YW7nUGqtSS4`}
          name={`BERNHARD BAUHOFER`}
          title={`Investor`}
          company={`Sparring Partners`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/bernhard-bauhofer-59416113/'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />


      </div>
</div>
    </>
  )
}
